var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"title black--text mb-2"},[_vm._v("Create Page")]),_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-card',{staticClass:"rounded-lg mb-5",attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('ValidationProvider',{ref:"title",attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-2 subtitle-1 font-weight-medium"},[_vm._v("Title Page")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","flat":"","error-messages":errors,"placeholder":"Title Page","color":"#F05326"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('ValidationProvider',{ref:"content",attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-2 subtitle-1 font-weight-medium"},[_vm._v("Content Page")]),_c('Editor',{attrs:{"api-key":"4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq","rows":"5","init":{
                height: 350,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link table',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
              }},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}])}),_c('div',{staticClass:"d-flex-col justify-space-between align-center mt-5"},[_c('div',{staticClass:"mb-2 subtitle-1 font-weight-medium"},[_vm._v("Status Page")]),_c('v-switch',{staticClass:"py-0",attrs:{"label":_vm.form.is_status ? 'Active' : 'Inactive',"color":"#F05326","error-messages":_vm.errors},model:{value:(_vm.form.is_status),callback:function ($$v) {_vm.$set(_vm.form, "is_status", $$v)},expression:"form.is_status"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-4 px-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","width":"120","height":"40","color":"grey lighten-3","loading":_vm.process.run,"to":("/company/page-list/" + (_vm.$route.params.id))}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","width":"120","height":"40","color":"primary","loading":_vm.process.run},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }